.container {
    width: 100vw;
    height: 100%;
    background-image: url(../assets/bg.png);
    background-position: bottom;
    background-repeat: no-repeat;
}

.chart1 {
    z-index: 2000;
    position: absolute;
    right: 5%;
    bottom: 6%;
    width: 8vw;
    height: 2.5vw;
    font-size: 1.1vw !important;
}

.chart2 {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    padding-bottom: 1%;
    position: absolute;
    right: 4.9%;
    bottom: 25%;
    width: 7.5vw !important;
    
    height: 2vw;
}

.chart3 {
    
    z-index: 2000;
    position: absolute;
    right: 20.5%;
    bottom: 24.2% !important;
    width: 8vw;
    height: 2.5vw;
}

.chart4 {
    z-index: 2000;
    position: absolute;
    right: 27.5%;
    bottom: 5.1% !important;
    width: 7vw !important;
    /* background-color: red; */
    /* display: flex; */
    /* align-items: center; */
    height: 2.5vw;
    font-size: 0.9vw !important;
}


.chart5 {
    z-index: 2000;
    position: absolute;
    right: 42.8%;
    bottom: 5.8%;
    width: 7.4vw !important;
    height: 2.5vw;
}

.chart6 {
    z-index: 2000;
    position: absolute;
    right: 43.9%;
    bottom: 15.5%;
    width: 7vw !important;
    height: 2.5vw;
}

.chart {
    width: 60vw;
    height: 30vw;
    position: absolute;
    bottom: 2vw;
    right: 2vw;
    background-size: contain;
    background-image: url(../assets/version3/chart.png);
    background-position: bottom;
    background-repeat: no-repeat;

}