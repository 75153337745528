.optionsContainer {
    z-index: 3000;
    position: absolute;
    background-color: white;
    width: 30vw;
    display: block;
    bottom: 12%;
    left: 1.5vw;
    height: 18vh;
    border-radius: 20px;
    color: black;
    columns: 4 auto;
    grid-auto-flow: row dense;
    padding: 3vw;
    justify-content: center;
    padding-left: 0vw;
}

.arrows1 {
    background-image: url(../assets/version3/arrows1.png);
    background-size: contain;
    position: absolute;
    width: 94vw;
    height: 8vw;
    background-repeat: no-repeat;
    left: 6vw;
    bottom: 53%;
}

.arrows2 {
    background-image: url(../assets/version3/arrows2.png);
    background-size: contain;
    position: absolute;
    width: 94vw;
    height: 5.4vw;
    background-repeat: no-repeat;
    left: 6vw;
    bottom: 42%;
}

.reds {
    background-image: url(../assets/version3/reds.png);
    background-size: contain;
    position: absolute;
    width: 94vw;
    height: 4.4vw;
    background-repeat: no-repeat;
    left: 6vw;
    bottom: 70%;
}

.topMessage {
    position: absolute;
    top: 5%;
    left: 5vw;
    font-family: luckiestGuy;
    font-size: 2vw;
    color: rgba(10, 70, 155, 0.8);
    text-align: left;
    width: 70vw
}

.text2 {
    background-image: url(../assets/version3/text2.png);
    background-size: contain;
    position: absolute;
    background-repeat: no-repeat;
    width: 14vw;
    height: 5vw;
    left: 6vw;
    bottom: 34vw;

}

.topArrow1 {
    position: absolute;
    width: 5.5% !important;
    height: 26%;
    /* background-color: rgba(210, 70, 155, 0.2); */
    left: 16.5vw;
    bottom: 15.5%;
}



.topArrow2 {
    position: absolute;
    width: 5.5% !important;
    height: 26%;
    /* background-color: rgba(210, 70, 155, 0.2); */
    left: 27.6vw;
    bottom: 10%;
}

.topArrow3 {
    position: absolute;
    width: 5.5% !important;
    height: 26%;
    /* background-color: rgba(210, 70, 155, 0.2); */
    left: 40.7vw;
    bottom: 13%;
}

.topArrow4 {
    position: absolute;
    width: 5.5% !important;
    height: 26%;
    /* background-color: rgba(210, 70, 155, 0.2); */
    left: 51.7vw;
    bottom: 14.5%;
}

.topArrow5 {
    position: absolute;
    width: 5.5% !important;
    height: 26%;
    /* background-color: rgba(210, 70, 155, 0.2); */
    left: 63.3vw;
    bottom: 12%;
}

.topArrow6 {
    position: absolute;
    width: 5.5% !important;
    height: 26%;
    /* background-color: rgba(210, 70, 155, 0.2); */
    left: 74.5vw;
    bottom: 10%;
}


.bottomArrow1 {
    position: absolute;
    width: 5.5% !important;
    height: 26%;
    /* background-color: rgba(210, 70, 155, 0.2); */
    left: 21.25vw;
    bottom: 19%;
}


.bottomArrow2 {
    position: absolute;
    width: 5.5% !important;
    height: 26%;
    /* background-color: rgba(210, 70, 155, 0.2); */
    left: 40.7vw;
    bottom: 19%;
}

.bottomArrow3 {
    position: absolute;
    width: 5.5% !important;
    height: 26%;
    /* background-color: rgba(210, 70, 155, 0.2); */
    left: 60.7vw;
    bottom: 19%;
}

.o1 {
    /* position: absolute; */
    bottom: 0vw;
    left: 1vw;
}

.o2 {
    /* position: absolute; */
    bottom: 0vw;
    left: 5vw;
}

.o3 {
    /* position: absolute; */
    bottom: 0vw;
    left: 9vw;
}

.o4 {
    /* position: absolute; */
    bottom: 0vw;
    left: 13vw;
}

.o5 {
    /* position: absolute; */
    bottom: 0vw;
    left: 17vw;
}

.o6 {
    /* position: absolute; */
    bottom: 0vw;
    left: 17vw;
}